import type { TypePolicies } from '@apollo/client'


export const defaultTypePolicies: TypePolicies = {
  Query: {
    // fields: {},
  },
  UserPayload: {
    keyFields: [],
    merge: true,
  },
  UserData: {
    keyFields: [],
    merge: true,
    fields: {
      orders: {
        keyArgs: [ 'input', [ 'orderType' ] ],
      },
      cart: {
        keyArgs: [ 'input', [ 'purchaseLevel' ] ],
      },
      reviews: {
        keyArgs: (args) => {
          const { offset, ...keys } = args?.input || {}

          return [ 'input', Object.keys(keys) ]
        },
        merge(existing, incoming, { variables }) {
          const offset = variables?.input?.offset || 0

          if (offset > 0) {
            const result = existing.reviews.slice(0)

            incoming.reviews.forEach((data, index) => {
              result[offset + index] = data
            })

            return {
              ...incoming,
              reviews: result,
            }
          }

          return incoming
        },
      },
      queue: {
        // ATTN split queue by plans
        keyArgs: [ 'input', [ 'forPlan' ] ],
        // pagination is manual
        read: (existing, options) => {
          const { limit } = options.args.input || {}

          // if we have enough items, we can return them
          if (existing?.queueItems?.length >= limit) {
            // slice to the requested length
            return {
              ...existing,
              queueItems: existing.queueItems.slice(0, limit),
            }
          }

          // if not enough items, we need to fetch them
          return undefined
        },
        merge: (existing, incoming) => {
          if (!incoming || !existing) {
            return incoming
          }

          const result = {
            ...incoming,
            queueItems: existing?.queueItems?.slice(0) || [],
          }

          // merge all items to the maximum length
          incoming?.queueItems?.forEach((item, index) => {
            result.queueItems[index] = item
          })

          return result
        },
      },
    },
  },
  PersonalInfo: {
    keyFields: [],
    merge: true,
  },
  AddressInfo: {
    keyFields: [],
    merge: true,
  },
  ScentbirdSubscription: {
    // singleton
    keyFields: [],
    merge: true,
  },
  ScentbirdSubscriptionStatus: {
    keyFields: [],
    merge: true,
  },
  CatalogueProductFilter: {
    keyFields: false,
    merge: false,
  },
  SubscriptionPlan: {
    // ATTN disable normalization, because SubscriptionPlan contains offer-depending fields
    keyFields: false,
    merge: false,
  },
  CaseSubscription: {
    keyFields: [ 'kind' ],
    merge: true,
  },
  OrdersData: {
    keyFields: false,
    fields: {
      data: {
        merge(existing, incoming, { variables }) {
          // fetchMore in the same context (e.g. ecommerce orders after ecommerce orders)
          if (variables?.input?.offset > 0) {
            return [ ...existing || [], ...incoming ]
          }

          // if offset 0 then we fetch orders from different context (e.g. subscription orders after ecommerce orders)
          // so we don't merge them and return new data only
          return incoming
        },
      },
    },
  },
  Rating: {
    keyFields: false,
    merge: false,
  },
  BrandInfo: {
    keyFields: [ 'name' ],
    merge: true,
    fields: {
      products: {
        merge: true,
      },
    },
  },
  ProductOfMonth: {
    // TODO: uid for ProductOfMonth type has description that it's available only for car scents, check with backend team - added on 2024-06-03 by girilloid
    keyFields: [ 'kind', 'month', 'year', 'product', [ 'id' ] ],
    merge: true,
  },
  // Queue
  Queue: {
    keyFields: false,
    merge: false,
    fields: {
      queueItems: {
        merge: false,
      },
    },
  },
  QueueItem: {
    keyFields: false, // disable normalization
    merge: false,
  },
  QueueProduct: {
    keyFields: false, // disable normalization
    merge: false,
  },
  OrderTracking: {
    keyFields: [ 'number' ],
    merge: false,
  },
  Product: {
    keyFields: (entity) => {
      // we disable normalization for ProductLineItem and ProductPurchaseItem
      if (entity.volume && entity.unit) {
        return false
      }

      // don't merge tradingItem productInfo
      if (entity.tradingItemProductUid !== undefined) {
        return false
      }

      // split product page info
      if (entity.productPageId !== undefined) {
        return [ 'uid', 'productPageId' ]
      }

      return [ 'uid' ]
    },
    merge: false,
  },
  TradingItems: {
    keyFields: false,
  },
  TradingItem: {
    keyFields: false,
  },
  TextProductDescriptionSection: {
    keyFields: false,
  },
  DataProductDescriptionSection: {
    keyFields: false,
  },
  // Cart
  Cart: {
    keyFields: false,
    merge: false,
  },
  ProductLineItem: {
    keyFields: false,
    merge: false,
  },
  SubscriptionLineItem: {
    keyFields: false,
    merge: false,
  },
  GiftSubscriptionLineItem: {
    keyFields: false,
    merge: false,
  },
  GiftCardLineItem: {
    keyFields: false,
    merge: false,
  },
  QueueLineItem: {
    keyFields: false,
    merge: false,
  },
  DiscountLineItem: {
    keyFields: false,
    merge: false,
  },
  ShippingLineItem: {
    keyFields: false,
    merge: false,
  },
  CreditsLineItem: {
    keyFields: false,
    merge: false,
  },
  TaxLineItem: {
    keyFields: false,
    merge: false,
  },
  // Payment
  Region: {
    keyFields: false, // to disable splitting country and region
  },
  Catalogue: {
    fields: {
      products: {
        // keyArgs are in the input object, there how it should be:
        // variables: { input: { groups: any, gender: any } }
        // -> keyArgs: [ 'input', [ 'groups', 'gender' ] ]
        keyArgs: (args) => {
          // ATTN we include limit to key to make a difference between 6 items, 40 items
          const { offset, ...keys } = args?.input || {}
          return [ 'input', Object.keys(keys) ]
        },
        merge(existing, incoming, { args }) {
          const offset = args?.input?.offset || 0
          // fetchMore in the same context
          if (offset > 0) {
            const result = existing?.products?.slice(0) ?? []

            incoming.products.forEach((data, index) => {
              result[offset + index] = data
            })

            return {
              ...incoming,
              products: result,
            }
          }

          // if offset 0 then we fetch products from different context
          // so, we don't merge them and return new data only
          return incoming
        },
      },
    },
  },
  CatalogueFilterValue: {
    keyFields: [ 'id', 'name' ],
  },
}
