import { useQuery as useApolloQuery } from '@apollo/client'
import type { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type { OperationVariables } from '@apollo/client/core'
import { useDevice } from 'device'
import { useApolloClient, type ApolloClientName } from './useApolloClients'


type Options<TData, TVariables> = Omit<QueryHookOptions<TData, TVariables>, 'client'> & {
  client?: ApolloClientName
}

type Result<TData, TVariables> = Omit<QueryResult<TData, TVariables>, 'loading'> & { isFetching: boolean }

const useQuery = <TData = any, TVariables = OperationVariables>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: Options<TData, TVariables>
): Result<TData, TVariables> => {
  const client = useApolloClient(options?.client || undefined)
  const { isBot } = useDevice()

  const { loading, error, ...rest } = useApolloQuery(query, {
    ...options,
    // we force all non-ssr queries for bots
    ssr: isBot || options?.ssr,
    client,
  })

  // @ts-expect-error
  if (__CLIENT__ && error?.networkError?.statusCode === 451) {
    throw error
  }

  return {
    isFetching: loading,
    error,
    ...rest,
  }
}


export default useQuery
