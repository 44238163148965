import { useMutation as useApolloMutation } from '@apollo/client'
import type { MutationHookOptions, MutationTuple } from '@apollo/client/react/types/types'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type { OperationVariables } from '@apollo/client/core'
import { type ApolloClientName, useApolloClient } from './useApolloClients'


type Options<TData, TVariables> = Omit<MutationHookOptions<TData, TVariables>, 'client'> & {
  client?: ApolloClientName
}

type Result<TData, TVariables> = [
  MutationTuple<TData, TVariables>[0],
  Omit<MutationTuple<TData, TVariables>[1], 'loading'> & { isFetching: boolean }
]

const useMutation = <TData = any, TVariables = OperationVariables>(
  mutation: TypedDocumentNode<TData, TVariables>,
  options?: Options<TData, TVariables>
): Result<TData, TVariables> => {
  const client = useApolloClient(options?.client || undefined)

  const [ mutate, { loading, ...rest } ] = useApolloMutation(mutation, {
    ...options,
    client,
  })

  return [
    mutate,
    {
      isFetching: loading,
      ...rest,
    },
  ]
}


export default useMutation
