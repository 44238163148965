import { useCallback } from 'react'
import { useLazyQuery as useApolloLazyQuery } from '@apollo/client'
import type { LazyQueryHookOptions, QueryResult } from '@apollo/client/react/types/types'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type { OperationVariables } from '@apollo/client/core'
import { useApolloClient, type ApolloClientName } from './useApolloClients'


type Options<TData, TVariables> = Omit<LazyQueryHookOptions<TData, TVariables>, 'client'> & {
  client?: ApolloClientName
}

type Result<TData, TVariables> = Omit<QueryResult<TData, TVariables>, 'loading'> & { isFetching: boolean }

type QueryTuple<TData, TVariables> = [
  (options?: LazyQueryHookOptions<TVariables>) => Promise<Result<TData, TVariables>>,
  Result<TData, TVariables>,
]

const processResult = <TData, TVariables>({ loading, ...rest }: QueryResult<TData, TVariables>) => ({
  isFetching: loading,
  ...rest,
})

const useLazyQuery = <TData = any, TVariables = OperationVariables>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: Options<TData, TVariables>
): QueryTuple<TData, TVariables> => {
  const client = useApolloClient(options?.client || undefined)

  const [ apolloExecute, apolloData ] = useApolloLazyQuery(query, {
    ...options,
    client,
  })

  const execute = useCallback((options) => {
    return apolloExecute(options).then(processResult)
  }, [ apolloExecute ])

  return [ execute, processResult(apolloData) ]
}


export default useLazyQuery
